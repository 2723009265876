@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  transition: all 0.3s ease;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-repeat: no-repeat;
}

html,
body {
  max-width: 100vw;
  background-color: #111;
}

a {
  color: inherit;
  text-decoration: none;
}

img,
video {
  max-width: none;
  height: 100%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  display: inline-block;
  animation: pulse 1s infinite;
}
