.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: transparent;
  box-shadow: -24px 0 transparent, 24px 0 transparent;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: transparent;
    box-shadow: -24px 0 #ff3d00, 24px 0 transparent;
  }
  66% {
    background: #ff3d00;
    box-shadow: -24px 0 #f0f0f0, 24px transparent;
  }
  100% {
    background: transparent;
    box-shadow: -24px 0 transparent, 24px 0 #ff3d00;
  }
}
